<template>
  <div class="goodsDetail">
    <div class="goodsDetail_header">
      <div class="goodsDetail_header_inner" style="margin-top: 20px;height: 40px;display: flex;align-items: center;">
        <div style="font-size: 18px">
          <i class="el-icon-s-home"></i>
          <span>{{ $t("Home") }}</span><i> > </i>
          <span>{{ $t("Goods Details") }}</span><i> > </i>
          <a style="color:#218DA0" @click="$router.push('/web/allGoods?cls_no='+goodsInfo['b_type'])">{{ lang =="zh-CN"?goodsInfo["BTypeClsCn"]:goodsInfo["BTypeClsEn"] }}</a><i> > </i>
          <a style="color:#218DA0" @click="$router.push('/web/allGoods?cls_no='+goodsInfo['m_type'])">{{ lang =="zh-CN"?goodsInfo["MTypeClsCn"]: goodsInfo["MTypeClsEn"] }}</a><i> > </i>
          <a style="color:#218DA0" @click="$router.push('/web/allGoods?cls_no='+goodsInfo['gt_cls_no'])">{{ lang =="zh-CN"?goodsInfo["GtClsNoClsCn"]:goodsInfo["GtClsNoClsEn"] }}</a>
          <!-- <a style="color:#218DA0" @click="$router.go(-1)">{{ $t("Back") }}</a> -->
        </div>
      </div>
    </div>
    <div class="goodsDetail_middle">
      <div class="goodsDetail_middle_inner gmi">
        <div style="margin-right: 10px;height: 600px;">
              <el-image
                style="width: 100px; height: 100px"
                v-for="(item,index) in smallImgList"
                :key="index"
                :src="item"
                @click="changeIndexImg(index)"
                fit="fit">
              </el-image>
        </div>
        <div class="goodsDetail_middle_inner_left">
          <div style="border: #ccc 1px solid;
					  height: 600px;
					  line-height: 600px;" v-show="showVideo.show">
            <video width="100%" height="100%" controls>
              <source :src="`${showVideo.src}`" type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <div class="pic-box" v-show="showVideo.show == false">
            <el-carousel :autoplay="false" height="600px" style="width:600px;" ref="carousel">
              <el-carousel-item v-for="(item,index) in smallImgList" :key="index" name="index">
                  <el-image
                    style="width: 600px; height: 600px"
                    :src="item"
                    :preview-src-list="smallImgList">
                  </el-image>
              </el-carousel-item>
            </el-carousel>

          </div>
          <!-- 视频图片切换 begin -->
          <div style="display: flex; margin-top: 2px" v-show="showVideo.showList">
            <p style="height: 80px; width: 80px; border: 1px solid #ccc" @click="videoImgShow(0)">
              <video id="video" :src="`${showVideo.src}`" loop="loop" muted="muted" height="100%" width="100%"></video>
            </p>
            <p style="
                height: 80px;
                width: 80px;
                border: 1px solid #ccc;
                margin-left: 10px;
              " @click="videoImgShow(1)">
              <img :src="`http://hwimg.xmvogue.com/thumb/${code}.jpg?x-oss-process=style/440`" height="100%" alt="" />
            </p>
          </div>
          <!-- 视频图片切换 end -->
        </div>
        <div class="goodsDetail_middle_inner_right" style="width: 100%">
          <p style="font-size: 24px; color: #212121">
            {{ goodsInfo.goodsName }}
          </p>
          <!-- 商品认证 -->
          <div>
            <ul style="display: flex; width: 600px; flex-wrap: wrap">
              <li v-for="(item, index) in credentialList" style="border: 1px solid #ccc; margin: 3px" :key="index">
                <img :src="`${item}`" height="50px" alt="" />
              </li>
            </ul>
          </div>

          <div class="goodsDetail_middle_inner_list gmil">
            <div class="prod_title gmil_pt" v-if="goodsInfo.xm_gdgp_show === true">
              <!--质保期-->
              <span style="color: red; font-size: 18px; margin-right: 10px;font-weight: bold;">{{ $t("Effective date") }}:</span>
              <span style="color: red; font-size: 18px; margin-right: 5px;font-weight: bold;">{{ goodsInfo.xm_gdgp }}
              </span>
            </div>
            <div class="prod_title gmil_pt">
              <!--商品货号-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Product Barcode") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ goodsInfo.item_no }}</span>
              <div>
                <i class="el-icon-document-copy" v-clipboard:copy="goodsInfo.item_no" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
              </div>
            </div>

            <div class="prod_title gmil_pt">
              <!--海关编码-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("HS Code") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ goodsInfo.hs_code }}
              </span>
            </div>

            <div class="prod_title gmil_pt">
              <!-- 商品材质 -->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Material") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ goodsExtend.material_en }}</span>
            </div>

            <div class="prod_title gmil_pt">
              <!--商品重量-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Weight") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ goodsExtend.weightdes }}</span>
            </div>

            <div class="prod_title gmil_pt">
              <!--商品尺寸-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("size") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ goodsExtend.lwh_en }}</span>
            </div>

            <div class="prod_title gmil_pt">
              <!--商品规格-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("MOQ") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ min }}</span>
            </div>

            <div class="prod_title gmil_pt">
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Carton Quantity") }}:</span>
              <span style="color: #5c6b77; font-size: 16px; margin-right: 5px">{{ goodsInfo.lot_spec }}</span>
            </div>

            <div class="prod_title gmil_pt" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              <!--门店售卖价-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Retail Price") }}:</span>
              <span style="color: #d92524;font-size: 16px;margin-right: 5px;">
                {{ $t("Price symbol") }}
                <template v-if="goodsInfo.sale_price">
                  {{ goodsInfo.sale_price.toFixed(2) }}
                </template>
              </span>
            </div>

            <div class="prod_title gmil_pt" v-if="goodsInfo.is_dis==0 && (userInfo.pid === 0 || userInfo.show_price === 1)" >
              <!--客户购买价-->
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Wholesale price") }}:</span>
              <span style="color: #d92524; font-size:16px; margin-right: 5px; font-weight: bold;">
                {{ $t("Price symbol") }}
                <template v-if="goodsInfo.base_price">
                  {{ goodsInfo.base_price.toFixed(2) }}
                </template>
              </span>
            </div>

            <div v-if="goodsInfo.is_dis==1 && (userInfo.pid === 0 || userInfo.show_price === 1)">
              <template  v-if="goodsInfo.did_good != null">
                <template v-if="parseInt(goodsInfo.did_good.dis_id) == 18">
                  <div class="prod_title gmil_pt">
                    <!--客户购买价-->
                    <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Discount Price") }}:</span>
                    <span style="color: #d92524; font-size:16px; margin-right: 5px; font-weight: bold; text-decoration:line-through;">
                      <template v-if="goodsInfo.dis_price">
                        {{ goodsInfo.dis_price.toFixed(2) }}
                      </template>
                    </span>
                  </div>
                </template>
                <!--  -->
                <template>
                  <div class="prod_title gmil_pt">
                    <!--客户购买价-->
                    <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Explosive Price") }}:</span>
                    <span style="color: #d92524; font-size:16px; margin-right: 5px; font-weight: bold;">
                      <template v-if="goodsInfo.did_good.fixed_price">
                          {{ goodsInfo.did_good.fixed_price.toFixed(2) }}
                      </template>
                    </span>
                    <img v-if="lang === 'zh-CN'" :src="require('@/assets/images/dis/bigsellCN.png')" alt="" style="width:60px">
                    <img v-if="lang === 'en'" :src="require('@/assets/images/dis/bigsale.png')" alt="" style="width:60px">
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="prod_title gmil_pt">
                  <!--客户购买价-->
                  <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Discount Price") }}:</span>
                  <span style="color: #d92524; font-size:16px; margin-right: 5px; font-weight: bold;">
                    <template v-if="goodsInfo.dis_price">
                      {{ goodsInfo.dis_price.toFixed(2) }}
                    </template>
                  </span>
                </div>
              </template>
            </div>

            <!-- 关联产品 -->
            <div>
              <ul style="display: flex; flex-wrap: wrap">
                <li style="
                    margin: 3px;
                    height: 100px;
                    width: 100px;
                    cursor: pointer;
                  " v-for="(item, index) in goodsRelated" :key="index">
                  <img :src="`http://hwimg.xmvogue.com/thumb/${item.sku_list}.jpg?x-oss-process=style/440`" style="border: 1px solid #ccc; width: 100px; height: 100px" @click="clickRelated(item)" alt="" />
                </li>
              </ul>
            </div>

            <div class="num_style">
              <span style="color: #333333; font-size: 16px; margin-right: 10px">{{ $t("Quantity") }}:
                <!--选购数量-->
              </span>
              <el-input-number size="large" :min="min" :max="max" :step="step" v-model="num" @change="handleChange" style="margin-top: 10px">
              </el-input-number>
            </div>

            <div style="display: flex;margin-top: 8px;" v-if="goodsInfo.us_num > 0 && goodsInfo.display_flag === 1">
              <div class="cli2cart">
                <p style=" font-size: 16px; cursor: pointer" @click="clickCart">
                  {{ $t("Add to Cart") }}
                  <span v-if="theGoodsCartInfo.num">[{{ theGoodsCartInfo.num }}]</span>
                </p>
                <i v-if="theGoodsCartInfo.num" style="margin-left: 10px; cursor: pointer" class="el-icon-delete" @click="delCartGoods()"></i>
              </div>
              <div class="collectDiv">
                <img v-if="isJoin===true" src="~@/assets/images/lover.png" style="width: 20px; height: 20px" alt="" />
                <p @click="add2Collect">
                  {{ $t("Collection") }}
                </p>
              </div>
            </div>

            <div style="margin-top: 20px; font-weight: 900; font-size: 28px; color: red;" v-else>
              {{ $t("coming soon") }}
            </div>

          </div>
        </div>
      </div>
    </div>
    <p class="pdInfomation">
      <span style="border-bottom: 1px solid #238da5;width:100%">{{ $t("Product Details Introduction") }}:</span>
    </p>
    <div style="width: 1400px; margin: 0 auto; padding: 20px" v-if="goodsExtend.show_features">
      <div v-if="goodsInfo.is_dis==1">{{ $t("Discounted products do not enjoy") }}</div>

      <p>
        <span>{{ $t("Features") }} </span>:{{ goodsExtend.show_features }}
      </p>
      <p>
        <span>{{ $t("Usage") }} </span>:{{ goodsExtend.show_usage }}
      </p>
      <p>
        <span>{{ $t("Notes") }}</span>:{{ goodsExtend.show_notes }}
      </p>
    </div>

    <div class="goodsDetail_footer">
      <div style="width: 53%">
        <img :src="`http://hwimg.xmvogue.com/detail/${code}-.jpg?x-oss-process=style/800`" style="width: 800px" alt="" />
      </div>
      <div class="goodsDetail_footer_inner">
        <div class="goodsDetail_footer_inner_title">
          <p style="font-size: 24px; color: #909399; margin-top: 10px">
            {{ $t("Related Products") }}
          </p>
        </div>
        <div class="goodsCard" v-for="(item, index) in reGoodsList" @click="reClick(item)" :key="index">
          <div class="goodsCard_left" style="cursor: pointer">
            <img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`" style="width: 120px; height: 120px" alt="" />
          </div>

          <div class="goodsCard_right">
            <div class="goodsCard_title">
              <span style="color: #000000; font-size: 16px; margin-top: 10px">{{
                  item.item_en
                }}</span>
            </div>
            <div class="goodsCard_title">
              <p style="color: #000000; font-size: 16px">{{ item.item_no }}</p>
              <div style="margin-left: 5px">
                <i class="el-icon-document-copy" @click="delCartGoods"></i>
              </div>
            </div>
            <div class="goodsCard_title" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              <div style="font-size: 16px; color: #000000">
                {{ $t("Purchase Price") }}:
              </div>
              <div style="font-size: 16px; color: #d92524; margin-left: 5px">
                ￥
                <template v-if="item.base_price">
                  {{ item.base_price.toFixed(2) }}
                </template>
              </div>
            </div>
            <div class="goodsCard_title" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              <div style="font-size: 16px; color: #000000; margin-left: 5px">
                {{ $t("Retail Price") }}:
              </div>
              <div style="font-size: 16px; color: #d92524; margin-left: 5px">
                ￥
                <template v-if="item.sale_price">
                  {{ item.sale_price.toFixed(2) }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import goodsDetail from "./js/index";

export default goodsDetail;
</script>
<style lang="scss" scoped>
.goodsDetail {
  width: 100%;

  &_header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f5f8fa;

    &_inner {
      width: 1400px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &_middle {
    width: 100%;
    // height: 610px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:40px 100px;

    &_inner {
      width: 1400px;
      // height: 610px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &_right {
        // height: 610px;
        width: 400px;
        margin: 0 0 0 100px;
      }

      &_list {
        height: 432px;
        width: 360px;
        margin-top: 15px;
      }
    }
  }

  .prod_title {
    width: 360px;
    line-height: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .num_style {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .cli2cart {
    background: #218DA0;
    height: 50px;
    width: 180px;
    color: white;
    margin-right: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .collectDiv {
    background: #218DA0;
    height: 50px;
    width: 180px;
    color: white;
    margin-right: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  &_footer {
    width: 100%;

    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 40px;

    &_inner {
      height: 800px;
      // background: #b0b0b0;
      border: 2px solid #f5f8fa;

      &_title {
        height: 50px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }
}

.goodsCard {
  width: 100%;
  // background: #f5f8fa;
  border: 2px solid #f5f8fa;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &_left {
    width: 150px;
    height: 168px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_right {
    width: 360px;
    height: 168px;
  }

  &_title {
    width: 330px;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.pdInfomation {
  font-size: 30px;
  // width: 100%;
  width: 1400px;
  color: #909399;
  padding: 10px 0px;
  margin: 0 auto;

}

.collectOn {
  color: white;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.collectNot {
  color: #ffffff;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.gmil{
  width: 100% !important;
  height: auto !important;
}
.gmil_pt{
  width: 100% !important;
}
.gmi{
  align-items: flex-start !important;
}
</style>
